* {
	font-family: Arial, Helvetica, sans-serif;
}

body {
	background-color: #111;
	background-image: url('./assets/bg.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	height: 120vh;
	overflow-x: hidden;
}

.w-100 {
	width: 100%;
}

.text-center {
	text-align: center;
}

.text-white {
	color: rgb(222, 222, 222);
}

.my-2 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.cursor-pointer {
	cursor: pointer;
}

.container {
	width: 100%;
	min-height: 80vh;
	padding-bottom: 5rem;
}

.wheel-wrapper {
	width: fit-content;
	margin: 0 auto;
}

.title {
	width: fit-content;
	margin: 2.5rem auto;
	color: rgb(222, 222, 222);
	text-shadow: 2px 2px black;
	background-color: rgba(0,0,0,0.6);
	padding: 0 0.5rem;
	border-radius: 8px;
}

.btn-spin-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 2rem;
}

.excluded-list {
	overflow-y: auto;
	height: 20vh;
	background-color: rgba(44,44,44,0.3);
	border-radius: 10px;
	color: rgb(222, 222, 222);
	padding: 10px 20px;
	width: 300px;
	margin: 0 auto;
}

.btn {
	padding: 0.6em 2em;
	border: none;
	outline: none;
	color: rgb(222, 222, 222);
	background: #111;
	cursor: pointer;
	position: relative;
	z-index: 0;
	border-radius: 10px;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-size: 1.2rem;
	min-width: 150px;
	border: 1px solid rgb(222, 222, 222);
	min-height: 50px;
}

.btn-spin:before {
	content: "";
	background: linear-gradient(
		45deg,
		#ff0000,
		#ff7300,
		#fffb00,
		#48ff00,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	-webkit-filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing-button-85 20s linear infinite;
	transition: opacity 0.3s ease-in-out;
	border-radius: 10px;
}

@keyframes glowing-button-85 {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.btn-spin:after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #222;
	left: 0;
	top: 0;
	border-radius: 10px;
}

.fast-food-icon {
	animation: pop 1.2s infinite;
	z-index: 99;
	font-size: 27px;
	position: absolute;
}

@keyframes pop {
	0% {
		transform: translate(-50%, -50%) scale(1.25, 0.75);
	}
	50% {
		transform: translate(-50%, -150%) scale(1, 1);
	}
	55% {
		transform: translate(-50%, -150%) rotate(15deg);
	}
	60% {
		transform: translate(-50%, -150%) rotate(-15deg);
	}
	65% {
		transform: translate(-50%, -150%) rotate(15deg);
	}
	70% {
		transform: translate(-50%, -150%) rotate(-15deg);
	}
	100% {
		transform: translate(-50%, -50%) scale(1.25, 0.75);
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(1px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-2px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(2px, 0, 0);
	}
}


/* width */
::-webkit-scrollbar {
	width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
	background: #888; 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555; 
}